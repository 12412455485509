import React, { useState, useEffect, useRef } from 'react';
import axios from 'axios';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import '../../style/admin/AdminArticles.scss';

export default function AdminArticles() {
  const [articles, setArticles] = useState([]);
  const [editingArticle, setEditingArticle] = useState(null);
  const [selectedTag, setSelectedTag] = useState('');
  const [formData, setFormData] = useState({
    title: '',
    tag: '',
    coverImage: null,
    content: '',
    createdBy: '',
  });

  const quillRef = useRef(null);  // Ajoute ceci pour la référence de Quill

  const tagsList = [
    { name: 'Dofus' },
    { name: 'DiabloIV' },
    { name: 'Satisfactory' },
    { name: 'ManofMedan' },
    { name: 'WorldofWarcraft' },
    { name: 'GTAV' },
    { name: 'Bramble' },
    { name: 'EpopeeFraternelle' },
    { name: 'Aventurea2' },
    { name: 'cyberpunk' },

    
  ];

  // Fetch articles (reste inchangé)
  useEffect(() => {
    async function fetchArticles() {
      try {
        const response = await axios.get(`${process.env.REACT_APP_API_URL}api/articles`);
        setArticles(response.data);
      } catch (error) {
        console.error('Erreur lors de la récupération des articles:', error);
      }
    }
    fetchArticles();
  }, []);

  const handleEditClick = (article) => {
    setEditingArticle(article._id);
    setFormData({
      title: article.title,
      tag: article.tag || '',
      coverImage: article.coverImage,
      content: article.content || '',
      createdBy: article.createdBy || '',
    });
  };

  const handleFormChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleImageChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      setFormData({
        ...formData,
        coverImage: file,
      });
    }
  };

  const handleSaveClick = async () => {
    const data = {
      title: formData.title,
      tag: formData.tag,
      content: formData.content,
      createdBy: formData.createdBy,
      coverImage: formData.coverImage instanceof File ? null : formData.coverImage,
    };

    try {
      await axios.put(`${process.env.REACT_APP_API_URL}api/articles/${editingArticle}`, data, {
        headers: { 'Content-Type': 'application/json' },
      });

      setArticles((prevArticles) =>
        prevArticles.map((article) =>
          article._id === editingArticle ? { ...article, ...formData } : article
        )
      );
      setEditingArticle(null);
      setFormData({
        title: '',
        tag: '',
        coverImage: null,
        content: '',
        createdBy: '',
      });
    } catch (error) {
      console.error("Erreur lors de la mise à jour de l'article:", error);
    }
  };

  const handleDeleteClick = async (id) => {
    try {
      await axios.delete(`${process.env.REACT_APP_API_URL}api/articles/${id}`);
      setArticles((prevArticles) => prevArticles.filter((article) => article._id !== id));
    } catch (error) {
      console.error('Erreur lors de la suppression de l\'article:', error);
    }
  };

  const uniqueTags = Array.from(new Set(articles.flatMap((article) => article.tag)));

  const filteredArticles = selectedTag
    ? articles.filter((article) => article.tag === selectedTag)
    : articles;

  return (
    <div className="admin-articles">
      <h2>Gestion des Articles</h2>

      <label>
        Filtrer par tag :
        <select value={selectedTag} onChange={(e) => setSelectedTag(e.target.value)}>
          <option value="">Tous les tags</option>
          {uniqueTags.map((tag) => (
            <option key={tag} value={tag}>
              {tag}
            </option>
          ))}
        </select>
      </label>

      <ul>
        {filteredArticles.map((article) => (
          <li key={article._id}>
            {editingArticle === article._id ? (
              <div>
                <input
                  type="text"
                  name="title"
                  value={formData.title}
                  onChange={handleFormChange}
                  placeholder="Titre"
                />
                <label>
                  Sélectionner un tag :
                  <select name="tag" value={formData.tag} onChange={handleFormChange}>
                    <option value="">Aucun</option>
                    {tagsList.map((tag) => (
                      <option key={tag.name} value={tag.name}>
                        {tag.name}
                      </option>
                    ))}
                  </select>
                </label>
                <input type="file" onChange={handleImageChange} />
                {formData.coverImage && (
                  <img
                    src={formData.coverImage instanceof File ? URL.createObjectURL(formData.coverImage) : formData.coverImage}
                    alt="Aperçu"
                    width="500"
                    style={{ margin: '10px 0' }}
                  />
                )}

                {/* Éditeur Quill */}
                <ReactQuill
                  ref={quillRef}
                  value={formData.content}
                  onChange={(value) => setFormData({ ...formData, content: value })}
                  modules={{
                    toolbar: [
                      [{ header: '1' }, { header: '2' }, { font: [] }],
                      [{ list: 'ordered' }, { list: 'bullet' }],
                      ['bold', 'italic', 'underline', 'strike', 'blockquote'],
                      [{ color: [] }, { background: [] }],
                      [{ align: [] }],
                      ['link', 'image', 'video'],
                      ['clean'],
                    ],
                  }}
                  placeholder="Écrire le contenu de l'article ici..."
                  style={{ height: '400px', marginBottom: '60px' }}
                />

                <input
                  type="text"
                  name="createdBy"
                  value={formData.createdBy}
                  onChange={handleFormChange}
                  placeholder="Créé par"
                />
                <button className="save" onClick={handleSaveClick}>
                  Enregistrer
                </button>
                <button className="cancel" onClick={() => setEditingArticle(null)}>
                  Annuler
                </button>
              </div>
            ) : (
              <div className='avantouverture'>
                <h3>{article.title}</h3>
                <p>{article.tag}</p>
                <img src={article.coverImage} alt={article.title} width="500" />
                <button onClick={() => handleEditClick(article)}>Modifier</button>
                <button onClick={() => handleDeleteClick(article._id)}>Supprimer</button>
              </div>
            )}
          </li>
        ))}
      </ul>
    </div>
  );
}
