// src/pages/ManOfMedan/ManOfMedanVideo.js
import React from 'react';
import VideoPlayer from '../../composants/VideoPlayer';
import NavContenu from '../../composants/Nav-contenu';

export default function ManOfMedanVideo() {
  const API_KEY = 'PLPYIAexfSG__ACWSRRrnye2GrFegczsQ1'; // Votre clé API
  const PLAYLIST_ID = ''; // ID de la playlist Man of Medan

  return (
    <>
      
      <NavContenu currentGame='cyberpunk'/>
      <VideoPlayer playlistId={PLAYLIST_ID} apiKey={API_KEY} title="Dernières vidéos de CyberPunk2077" />
    </>
  );
}
