import React from 'react';
import { useNavigate } from 'react-router-dom';
import '../style/Jeux.scss';

// Composant de navigation

// Images
import Dofus from '../images/Jeux/Dofus3.webp';
import Satis from '../images/Jeux/Satis.webp';
import Diablo4 from '../images/Jeux/Diablo4.webp';
import Wow from '../images/Jeux/WOW.webp';
import DarkPictures from '../images/Jeux/FriendsPass_Skull.png';
import Bramble from '../images/Jeux/Bramble.webp';
import GTA from '../images/Jeux/GTA_V.webp';
import Vrac from '../images/Jeux/Vrac.webp';
import humanity from '../images/Jeux/Humanity.webp';
import cyberpunk from '../images/Jeux/Cyberpunk.webp';

// Tableau des jeux avec les images et chemins
const games = [
  { name: 'Dofus', path: '/dofus', img: Dofus },
  { name: 'CyberPunk 2077', path: '/cyberpunk2077', img: cyberpunk },
  { name: 'Diablo 4', path: '/diabloIV', img: Diablo4 },
  /*{ name: 'Satisfactory', path: '/satisfactory', img: Satis },*/
  { name: 'The Dark Pictures Anthology', path: '/dark-pictures', img: DarkPictures },
  /*{ name: 'World of Warcraft', path: '/worldofwarcraft', img: Wow },*/
  { name: 'GTA V', path: '/gtav', img: GTA },
  { name: 'Bramble', path: '/bramble', img: Bramble },
 /* { name: 'Jeux en vrac', path: '/jeux-en-vrac', img: Vrac },*/
  /* { name: 'Humanity', path: '/humanity', img: humanity },*/
  
];

export default function Jeux() {
  const navigate = useNavigate();

  const handleNavigation = (path) => {
    navigate(path);
  };

  return (
    <div>
 
      <div className="jeux-container">
        {games.map((game) => (
          <div 
            key={game.name} 
            className="game-card" 
            onClick={() => handleNavigation(game.path)}
          >
            <img src={game.img} alt={game.name} className="game-image" />
            <h3>{game.name}</h3>
          </div>
        ))}
      </div>
    </div>
  );
}
