// src/pages/Diablo4/Diablo4Home.js
import React, { useState, useEffect } from 'react';
import axios from 'axios';
import NavContenu from '../../composants/Nav-contenu'
import Card from '../../composants/Card';
import { useNavigate } from 'react-router-dom';

export default function Diablo4Home() {
  const [articles, setArticles] = useState([]);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchArticles = async () => {
      try {
        const response = await axios.get(`${process.env.REACT_APP_API_URL}api/articles?tag=cyberpunk`);
        // Trier les articles par date de création (du plus récent au plus ancien)
        const sortedArticles = response.data.sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt));
        setArticles(sortedArticles);
      } catch (error) {
        console.error('Erreur lors de la récupération des articles', error);
      }
    };

    fetchArticles();
  }, []);

  const handleCardClick = (article) => {
    navigate(`/article/${article._id}`, { state: { article } }); // Passer l'article dans l'état
  };

  return (
    <div>
      
      <NavContenu  currentGame='cyberpunk'/>
      <div className='actu'>
        <h1>CyberPunk 2077 Aventure</h1>
        <ul className='all-card'>
          {articles.map((article) => (
            <Card key={article._id} article={article} onClick={() => handleCardClick(article)} />
          ))}
        </ul>
      </div>
    </div>
  );
}
